// extracted by mini-css-extract-plugin
export const articles_container = "JournalComponent-module--articles_container--1h4wu";
export const article = "JournalComponent-module--article--hY4GD";
export const header = "JournalComponent-module--header--3mOCD";
export const sort = "JournalComponent-module--sort--3yMBA";
export const active_sort_criteria = "JournalComponent-module--active_sort_criteria--2tfg-";
export const projects_container = "JournalComponent-module--projects_container--1ly2E";
export const projects_title = "JournalComponent-module--projects_title--ZpDkV";
export const project_img_container = "JournalComponent-module--project_img_container--2lFYH";
export const journal_articles_container = "JournalComponent-module--journal_articles_container--1KrLX";
export const single_article_card = "JournalComponent-module--single_article_card--REKV7";
export const see_more = "JournalComponent-module--see_more--1lMjt";
export const bouncy = "JournalComponent-module--bouncy--2Mz0b";
export const pop = "JournalComponent-module--pop--2vhGx";