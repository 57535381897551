import * as React from "react";
import { graphql } from "gatsby"
import Layout from "@components/layout";
import JournalComponent from "@components/Articles";
import SEO from "@components/seo";
import { MetadataService } from "../../utils/MetadataService";

const Journal = ({ uri, data }) => {

  const { pageMetadata } = data;
  const metadata = MetadataService.parseMetadata(JSON.parse(JSON.stringify(pageMetadata || {}))).content;

  return (
    <Layout path={uri}>
      <SEO title={metadata.page_title} />
      <JournalComponent metadata={metadata} />
      <p></p>
    </Layout>
  );
}


export const pageQuery = graphql`
  query ArticlesComponentQuery {
    pageMetadata(page_id: {eq: "articles"}) {
      content,
      page_id
    }
  }
`

export default Journal;
