import * as React from "react";
import { useState, useEffect } from "react";
import { useLocation } from '@reach/router';
import { Link, useStaticQuery, graphql } from "gatsby";
// import { Link } from "gatsby";
import * as styles from "./JournalComponent.module.scss";
import SingleArticleCard from "@components/Articles/SingleArticleCard";
import ContactForm from "@components/ContactForm"

const JournalComponent = ({metadata}) => {
  const data = useStaticQuery(graphql`
    {
      allArticles {
        nodes {
          id
          title
          staff {
            id,
            name,
            position,
            picture {
              name,
              image
            }
          }
          created
          header
          image
          published
        }
      }
    }
  `);

  var articles = data.allArticles.nodes;
  const location = useLocation().pathname;
  const test_path = '/test/'

  if (!location.startsWith(test_path)) {
    articles = articles.filter(function (el) {
      return el.published == true;
    });
  }

  const [activeSortCriteria, setActiveSortCriteria] = useState("popular");

  const SortCriteria = {
    popular: { name: "Most Popular", criteria: (a, b) => a > b },
    featured: { name: "Featured", criteria: (a, b) => a > b },
    newFirst: { name: "New to Old", criteria: (a, b) => a.created > b.created },
    oldFirst: { name: "Old to New", criteria: (a, b) => a.created > b.created }
  };

  const handleSortCriteria = () => {};

  return (
    <>
      <div className={`${styles.header}`}>
        <h1>{metadata.title}</h1>

        <div className={`${styles.sort} container`}>
          {/* <p>Sort by:</p>
          {Object.keys(SortCriteria).map((criteria, i) => (
            <button
              key={i}
              onClick={e => setActiveSortCriteria(criteria)}
              style={{ backgroundColor: "unset" }}
              className={
                activeSortCriteria === criteria
                  ? styles.active_sort_criteria
                  : ""
              }
            >
              {SortCriteria[criteria].name}
            </button>
          ))} */}
        </div>
      </div>

      <div className={`${styles.journal_articles_container} container`}>
        {articles?.length > 0 &&
          [...articles.slice(0, 2)].map((article, i) => (
            <>
              <div className="container">
                <SingleArticleCard {...article} key={i} />
              </div>
            </>
          ))}
      </div>

      <div className={styles.projects_container}>
        <Link to={metadata.call_to_action.link}>
          <h2 className={styles.projects_title}>
            {metadata.call_to_action.text}
          </h2>
          {/* <div className={styles.project_img_container}></div> */}
        </Link>
      </div>

      {articles?.length > 12 && (
        <div className={styles.journal_articles_container}>
          {[...articles.slice(2, 12)].map((article, i) => (
            <div className="container">
              <SingleArticleCard {...article} key={i} />
            </div>
          ))}
        </div>
      )}

      {articles?.length > 12 && (
        <button className={styles.see_more}>More...</button>
      )}

      <div className="container">
        <ContactForm />
      </div>
    </>
  );
};

export default JournalComponent;
